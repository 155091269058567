import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function Originux() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c7_c7502ab8ac.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Originux
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                The Bangalore based UI UX design company has made a place in the
                list of <b>top 10 UI UX design companies in Chennai</b>.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $30.56-$60.11 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 20-49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.originux.com/" target="_blank">
                  https://www.originux.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 3rd Floor, No 5, 1st Cross Rd, K.R.Colony, Krishna
                  Reddy Colony, Domlur, Bengaluru, Karnataka 560071
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                The Bangalore based UI UX design company has made a place in the
                list of <b>top 10 UI UX design companies in Chennai</b>. The
                company, founded in 2018, did not take too long to make a place
                in the competitive tech design industry, thanks to the
                leadership of Vaishnavi Reddy and a team of expert designers and
                coders. Although the company is headquartered in Bangalore, the
                company also operates in Chennai, San Francisco, Dubai, and
                Manchester. The Originux UX team has a collective experience of
                80 years, which allows them to create foolproof design and
                marketing strategies. The team follows design sprints and
                provides prototypes to ensure the end product is perfect.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
